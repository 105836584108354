<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-09-17 18:44 -->
<!-- Description:  -->
<template>
  <div>
    <h4>Control diario</h4>
    <div class="mb-3 row">
      <div class="col-3">
        <input
          type="date"
          v-model="date"
          class="form-control"
          @change="reload()"
        />
      </div>
    </div>

    <TableControlView :list="list" :key="date"></TableControlView>
    <div class="row">
      <div class="col-lg-4">
        <ControlTableResumeByEmployee
          ref="elControlTableResumeByEmployee"
        ></ControlTableResumeByEmployee>
      </div>
      <div class="col-lg-4">
        <TableResumePayMethod
          ref="elTableResumePayMethod"></TableResumePayMethod>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <AddOutputsByDate :key="date" :date="date"></AddOutputsByDate>
      </div>
    </div>
  </div>
</template>
<script>
import myMoment from "src/lt/utils/myMoment.js";
import { DentalClinicService } from "../service.js";
import AddOutputsByDate from "./AddOutputsByDate.vue";
import TableControlView from "./TableControlView.vue";
import ControlTableResumeByEmployee from "./ControlTableResumeByEmployee.vue";
import TableResumePayMethod from "./TableResumePayMethod.vue";

// script content
export default {
  name: "ControlDiario",
  components: {
    AddOutputsByDate,
    TableControlView,
    ControlTableResumeByEmployee,
    TableResumePayMethod,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [],
    total_1: 0,
    total_2: 0,
    date: myMoment().getCurrentDate(),
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      this.$refs.elControlTableResumeByEmployee.reset();
      this.$refs.elTableResumePayMethod.reset();
      let res = await DentalClinicService.getControl({
        date: this.date,
      });
      const sumatoria = function(col) {
        return (p, c) => {
          return p + (c[col] ? parseFloat(c[col]) : 0);
        };
      };
      this.total_1 = res.reduce(sumatoria("unit_price"), 0);
      this.total_2 = res.reduce(sumatoria("amount_paid"), 0);
      this.list = res;
      this.$refs.elControlTableResumeByEmployee.generate(res);
      this.$refs.elTableResumePayMethod.generate(res);
    },
    //
    //
  },
};
</script>
<style scoped></style>
